<template>
  <div style="width: 100vw;overflow: hidden;position: relative;" @click.="closeModal()">
    <div class="tipsWrapper" v-if="showBrowerPage">
      <div style="text-align: right;margin: 70px;"><img src="../assets/easily.png" style="width: 80px;" /></div>
      <div style="text-align: center;font-size: 25px;">
        1.点击右上角按钮
        <br />
        2.选择“在浏览器打开”
      </div>
      <button class="btn" v-clipboard:error="onError"
     v-clipboard:copy="linkUrl"  v-clipboard:success="onCopy">复制下载链接至浏览器打开</button>
    </div>
    <!-- 背景颜色部分 -->
    <div class="headerbg rushow">
      <div class="headershow">
        <div class="reactout"></div>
        <div class="reactinner"></div>
      </div>
    </div>
    <!-- 首页内容区域 -->
    <div class="content" style="position: relative;z-index: 1;height: 80vh;">
      <!-- 头部区域 -->
      <div class="topcont" style="margin: 20px;padding: 10px;">
        <img class="fiximg" src="../assets/icon.png" alt="AI文案写作喵">
        <span data-i18n="index.public.name">AI文案写作喵</span>
      </div>
      <div class="centerText">
        <div>
          <!-- 这里是描述区 -->
          <div>
            <h1 class="headTitle" style="color: #fff;">你的文案写作帮手</h1>
          </div>
          <div class="specialBoxWrapper">
            <div class="specialBox">
              <div class="specTitle">一键生成</div>
              <div class="specDesc">快速|精准|个性化</div>
            </div>
            <div class="specialBox">
              <div class="specTitle">长期</div>
              <div class="specDesc">免费使用</div>
            </div>

            <div class="specialBox">
              <div class="specTitle">100+</div>
              <div class="specDesc">应用场景支持</div>
            </div>
          </div>

          <div class="downWrapper">
            <a :href="linkUrl" download @click.stop="download()">
              <div class="downloadBox" style="background-color: #fff;">
                <img src="../assets/Android.png" alt="">
                <strong>v1.2.3 Android下载</strong>
              </div>
            </a>
            <div>
              <div class="downloadBox" style="background-color: #fff;">
                <img src="../assets/IOS.png" alt="">
                <strong>IOS敬请期待</strong>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        <!-- 右边显示 -->
        <div class="rightBox">
          <img src="../assets/aimeowIcon_ch.png">
        </div>
      </div>
    </div>
    <!-- 次行专栏区域 -->
    <div class="otherWrapper">
      <div class="speclDetail" style="position: relative;z-index: 1;">
        <div>
          <div class="speclDetail_head">
            <div>
              <img class="speclDetail_icon" src="../assets/一键招呼.png" />
            </div>
            <div class="speclDetail_title">
              <h2>一键生成 文案不头疼</h2>
            </div>
          </div>
          <div class="textDesc">写文案快速、精准、个性化、省力和可定制等特点，可以为作者带来更高效和高质量的写作体验。</div>
        </div>
        <div class="speclDetail_img">
          <img style="height: 500px;" src="../assets/P-1561379-3EEF7071O.png" />
        </div>
      </div>

      <div class="speclDetail" style="position: relative;z-index: 1;">
        <div>
          <div class="speclDetail_head">
            <div>
              <img class="speclDetail_icon" src="../assets/免费.png" />
            </div>
            <div class="speclDetail_title">
              <h2>不充钱也能玩</h2>
            </div>
          </div>
          <div class="textDesc">体验智能魅力，尽在我们的AI文案助手！无论你是否充钱，都能享受到优质的服务。创作文章，不再是难题。</div>
        </div>
        <div class="speclDetail_img">
          <img style="width: 300px;" src="../assets/wx_home_freetip.png" />
        </div>
      </div>

      <div class="speclDetail" style="position: relative;z-index: 1;">
        <div>
          <div class="speclDetail_head">
            <div>
              <img class="speclDetail_icon" src="../assets/更多应用.png" />
            </div>
            <div class="speclDetail_title">
              <h2>多场景适用</h2>
            </div>
          </div>
          <div class="textDesc">无论你在何种场景下，我们的AI文案助手都能为你提供适用的文案建议。无论是宣传推广、产品介绍、还是新闻报道，我们都能满足你的需求</div>
        </div>
        <div class="speclDetail_img">
          <img style="height: 500px;" src="../assets/more.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      showBrowerPage: false,
      linkUrl:"https://webrtc.mszn1.com/AIMeow/appDwnload/ai_meow_v1.2.3.apk"
    }
  },
  methods: {
    // copy(){
    //     //console.log("复制链接")

    // },
    onCopy(){
        // this.$message({
        //     message:'复制成功！',
        //     type:'success'
        // })
        console.log("onCopy")
    },
    // 复制失败
    onError(){
      //  this.$message({
      //       message:'复制失败！',
      //       type:'error'
      //   })
      console.log("onError")
    },
    closeModal() {
      console.log("closeModal")
      if (!this.showBrowerPage) {
        this.showBrowerPage = false
      }
    },
    download() {
      var userAgent = window.navigator.userAgent.toLowerCase();
      console.log(userAgent)
      if (userAgent.indexOf('micromessenger') !== -1) {
        console.log('当前页面在微信中打开');
        this.showBrowerPage = true
      } else {
        console.log('其他浏览器');
      }
    }
  }
}
</script>

<style scoped>
.btn{
  background-color: #dfb25f;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    border: none;
    margin: 10px;
}
.tipsWrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
}

* {
  padding: 0;
  margin: 0;
}

body,
html {
  font-size: 20px;
}

a {
  text-decoration: none;
}



.specTitle {
  font-weight: 700;
}

.headerbg .reactinner,
.headerbg .reactout {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50px;
  border-radius: 2.5rem;
  background: hsla(0, 0%, 100%, .05);
}

.headerbg .reactout {
  width: 56%;
  height: 360px;
  height: 18rem;
}

.headerbg .reactinner {
  width: 40%;
  height: 520px;
  height: 26rem;
}


/* 媒体查询，针对PC端 */
@media screen and (min-width: 668px) {
  .otherWrapper {
    max-width: 100vw;
  }

  .headerbg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 700px;
    height: 38rem;
    overflow-x: hidden;
  }

  .headerbg .headershow {
    width: 100%;
    height: 95%;
    border-radius: 0 0 50px 0;
    border-radius: 0 0 2.5rem 0;
    box-shadow: 1px 1px 35px 15px rgba(229, 227, 81, 0.4);
    filter: progid:DXImageTransform.Microsoft.Shadow(color="rgba(81,90,229,0.4)", Direction=125, Strength=9);
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="../images/headbg.png", sizingMethod="scale");
    background: linear-gradient(120deg, #eb9222, #e1bb6c 30%, #dfb25f 50%, #e9c49b 90%, #e1a65b);
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#3150ed, endcolorstr=#db38df, gradientType=1);
  }

  .rightBox img {
    width: 70%;
  }

  .speclDetail {
    transition: 0.2s linear;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px 0;
  }

  .speclDetail_head {
    display: flex;
    align-items: center;
  }

  .speclDetail_icon {
    width: 40px;
  }

  .speclDetail_title {
    padding: 0 8px;
  }

  .headerbg .headershow {
    -webkit-transform: rotate(4deg) translate(-7rem, -3rem);
    /* transform: rotate(5deg) translate(-8.5rem, -2rem); */
    transform: rotate(4deg) translate(-7rem, -rem);
  }

  .fiximg {
    width: 24px;
    width: 1.2rem;
    height: 24px;
    height: 1.2rem;
    position: relative;
    top: -2px;
    top: -0.1rem;
  }

  .topcont img {
    width: 24px;
    width: 1.2rem;
    height: 24px;
    height: 1.2rem;
    position: relative;
    top: 5px;
  }

  .topcont span {
    color: #fff;
    font-size: 20px;
    font-size: 1rem;
    margin-left: 10px;
    margin-left: 0.5rem;
  }

  /* 特点描述框 */
  .specialBoxWrapper {
    display: flex;
    gap: 20px;
    margin: 50px 0;
  }

  .specialBox {
    border: 3px solid #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
  }

  .specialBox {
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  .specDesc {
    font-size: 13px;
    color: #61696d;
    text-align: center;
    white-space: nowrap;
  }

  .downWrapper {
    display: flex;
    gap: 20px;
  }

  .downloadBox {
    background-color: #fff;
    padding: 8px;
    width: 190px;
    border-radius: 28px;
    margin: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .downloadBox img {
    height: 50px;
  }

  .downloadBox strong {
    color: #dfb25f;
    font-size: 14px;
  }

  .textDesc {
    max-width: 346px;
    text-indent: 2em;
    color: #999;
  }

  .centerText {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

}

/* 媒体查询，针对移动端 */
@media screen and (max-width: 767px) {
  .headerbg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 700px;
    height: 38rem;
  }

  .headerbg .headershow {
    width: 100%;
    height: 120%;
    border-radius: 0 0 50px 0;
    box-shadow: 1px 1px 35px 15px rgba(229, 227, 81, 0.4);
    background: linear-gradient(120deg, #eb9222, #e1bb6c 30%, #dfb25f 50%, #e9c49b 90%, #e1a65b);
  }

  .rightBox {
    text-align: center;
  }

  .rightBox img {
    width: 50%;
  }

  /* 特点描述框 */
  .specialBoxWrapper {
    display: flex;
    gap: 20px;
    margin: 50px 0;
    justify-content: space-around;
  }

  .downWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 20px;
  }

  .content {
    font-size: 14px;
  }

  .headTitle {
    text-align: center;
  }

  .otherWrapper {
    margin: 90px 0;
    max-width: 100vw;
  }

  .speclDetail {
    transition: 0.2s linear;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0;
    align-items: center;
  }

  .speclDetail_head {
    display: flex;
    align-items: center;
  }

  .speclDetail_icon {
    width: 40px;
  }

  .speclDetail_title {
    padding: 0 8px;
  }

  .speclDetail_img {
    margin: 30px 0;
  }

  .headerbg .headershow {
    -webkit-transform: rotate(4deg) translate(-7rem, -3rem);
    /* transform: rotate(5deg) translate(-8.5rem, -2rem); */
    transform: rotate(4deg) translate(-7rem, -rem);
  }

  .fiximg {
    width: 24px;
    width: 1.2rem;
    height: 24px;
    height: 1.2rem;
    position: relative;
    top: -2px;
    top: -0.1rem;
  }

  .topcont img {
    width: 24px;
    width: 1.2rem;
    height: 24px;
    height: 1.2rem;
    position: relative;
    top: 5px;
  }

  .topcont span {
    color: #fff;
    font-size: 20px;
    font-size: 1rem;
    margin-left: 10px;
    margin-left: 0.5rem;
  }

  /* 特点描述框 */
  .specialBoxWrapper {
    display: flex;
    gap: 20px;
    margin: 50px 0;
  }

  .specialBox {
    border: 3px solid #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
  }

  .specialBox {
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  .specDesc {
    font-size: 13px;
    color: #e4e6e7;
  }

  .downWrapper {
    display: flex;
    gap: 20px;
  }

  .downloadBox {
    background-color: #fff;
    padding: 8px;
    width: 190px;
    border-radius: 28px;
    margin: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .downloadBox img {
    height: 50px;
  }

  .downloadBox strong {
    color: #dfb25f;
    font-size: 14px;
  }

  .textDesc {
    max-width: 346px;
    text-indent: 2em;
    color: #999;
  }
}
</style>
